import React, { useState, useEffect } from 'react';
import { Col, Drawer, Dropdown, Menu, Popover, Row, Select, Table, Avatar, Button } from "antd";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoAdd, IoEllipsisHorizontal } from "react-icons/io5";
import EquipeDrawerPage from "./drawer";
import { NavLink } from "react-router-dom";
import AttribuerModalView from "../logements/create/equipe-de-menage/modal/attribuer-logement";
import PourboireConfirmationModalView from "../logements/create/equipe-de-menage/modal/pourboire-confirmation";
import SuccessNotification from "../notification-global/success";
import PourboireModalView from "../logements/create/equipe-de-menage/modal/pourboire";
import BloquerProfilModalView from "../logements/create/equipe-de-menage/modal/bloquer-profil";
import moment from "moment";
import { useEquipePerformanceGetMutation } from './services/equipe-api';
import {API_ROOT, convertDateStringToTimeStringDateOnly} from '../../utils/global-var';
import "moment/locale/fr";
import "./styles/style.scss"
import "./styles/styleMax1020.scss"

function EquipePage() {
    const [open, setOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState([]);
    const [showModalAttribution, setShowModalAttribution] = useState(false);
    const [showModalPourboire, setShowModalPourboire] = useState(false);
    const [showModalPourboireConfirmation, setShowModalPourboireConfirmation] = useState(false);
    const [showNotif, setShowNotif] = useState(false);
    const [showModalBloquer, setShowModalBloquer] = useState(false);
    const [equipePerformanceGet] = useEquipePerformanceGetMutation();
    const [responsables, setResponsables] = useState([])
    const [stats, setStats] = useState([])
    const [selectPartner, setSelectPartner] = useState("RESPONSABLE")
    const [equipe, setEquipe] = useState()

    const handleFetchEquipe = async (data) => {
        equipePerformanceGet(data).unwrap()
            .then((res) => {
                setResponsables(res.data.responsables);
                setStats(res.stats)
            })
            .catch(() => {
                // setIsLoadingLogements(false)
            });
    };

    useEffect(() => {
        const data = ({ "pageView": selectPartner })
        handleFetchEquipe(data)
    }, [selectPartner]);

    const showDrawer = (record) => {
        setCurrentRecord(record);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const getMenuItems = (record) => [
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => setShowModalAttribution(true)}>
                <div className="imageSide">
                    <img src="../assets/img/attribuer-lgt-icon.png" alt="add" />
                </div>
                Attribuer un logement
            </div>,
            key: '1itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => {
                setShowModalPourboire(true);
            }}>
                <div className="imageSide">
                    <img src="../assets/img/pourboire-icon.png" alt="add" />
                </div>
                Verser un pourboire
            </div>,
            key: '2itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className="add-logement2-item sans-pro-regular" style={{ color: "red" }} onClick={() => setShowModalBloquer(true)}>
                    <div className="imageSide">
                        <img src="../assets/img/bloquer-profil-icon.png" alt="add" />
                    </div>
                    Bloquer ce profil
                </div>,
            key: '6itemsOption',
        },
    ];

    const columns = [
        {
            title: "Prénom, Nom, Entreprise",
            width: "22%",
            render: (record) => (
                <div style={{ cursor: 'pointer', color: "#263754", fontSize: "15px" }} onClick={() => (showDrawer(record), setEquipe(record))}>
                    <div className="avatar">
                        <Avatar src={`${API_ROOT}/${record.avatar}`} />
                    </div>
                    <div className="info">
                        <div className="name sans-pro-semi-bold">{record.firstname} {record.lastname}</div>
                        <div className="desc">{record.entreprise}</div>
                    </div>
                </div>
            ),
        },

        {
            title: 'Statut',
            width: "22%",
            render: (record) => (
                <div>
                    {/* {(record.statut === "actif") && (<div className="badge-green" >Actif</div>)}
                    {(record.statut === "pending") && (<div className="badge-blue" >Invitation envoyée</div>)}
                    {(record.statut === "wait") && (<div className="wait">
                        <div className="badge-grey" >En attente de prestations</div>
                        <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                    </div>)}
                    {(record.statut === "block") && (<div className="wait">
                        <div className="badge-red" >Bloqué</div>
                        <Popover placement="top" content={contentInfoBlocked} overlayClassName="infoContentLogement">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                    </div>)} */}
                </div>
            ),
        },

        {
            title: 'Numéro de téléphone',
            width: "15%",
            render: (record) => (
                <div>
                    {record.phone}
                </div>
            ),
        },
        {
            title: 'Logements gérés',
            width: "10%",
            render: (record) => (
                <div>
                    <div className="nbLogement">
                        {record.logements_total_number}
                    </div>
                </div>
            ),
        },
        {
            title: 'Travaille avec vous depuis le...',
            width: "17%",
            render: (record) => (
                <div>{convertDateStringToTimeStringDateOnly(record.createdAt)}</div>
            ),
        },
        {
            title: "",
            key: "option",
            width: "6%",
            render: (record) => (
                <div className="tab-option">
                    <Dropdown
                        overlay={<Menu items={getMenuItems(record)} />}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px" }} />
                    </Dropdown>
                </div>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const contentInfoSuccess = (
        <div className="infoLogement">
            <p>
                Représente le pourcentage de <br />
                prestations effectuées sur vos <br />
                logements dont la note globale <br />
                est supérieure ou égale à 4 sur <br />
                5.
            </p>
        </div>
    );
    const contentInfoSaturation = (
        <div className="infoLogement">
            <p>
                Représente le taux de remplissage <br />
                du planning de l’équipe <br />
                sélectionnée. Plus ce taux est <br />
                élevé, moins l’équipe est flexible <br />
                et la qualité des prestations est <br />
                souvent affectée.
            </p>
        </div>
    );
    const items = [
        /* {
             label: <div onClick={() => null}  className="sans-pro-regular color-5B6E8C">
                 <IoSyncSharp />  <span className="text">Demander une équipe pour un nouveau logement</span>
             </div>,
             key: '2',
         },
         {
             label: <div  className="sans-pro-regular color-5B6E8C" onClick={() => null}>
                 <EditOutlined />  <span className="text">Changer l’équipe sur un logement actif</span>
             </div>,
             key: '3',
         },
         {
             label: <div  className="sans-pro-regular color-5B6E8C">
                 <NavLink to={"/inviter-un-tierce"} className="color-5B6E8C"><PlusCircleOutlined /> <span className="text">Inviter une équipe tierce sur Kliner</span></NavLink>
             </div>,
             key: '4',
         },*/
    ];

    let locale = {
        emptyText: (
            <div className='empty-blc'>
                <div className='blc'>
                    <div className='home-icon'>
                        <img src='assets/img/icon-logement.png' />
                    </div>
                    <h4>Constituez vos équipes de ménage</h4>
                    <p>Ajoutez vos équipes externes sur Kliner ou demandez à Kliner de vous trouver des aides ménagères si vous n’en avez pas.</p>
                    <a href='https://help.kliner.com/fr/articles/9349394' target='_blank' className="en-savoir-plus">En savoir plus <FaArrowRightLong className='icon' /></a>
                </div>
            </div>
        )
    };

    return (
        <div className='equipe-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Equipe d'entretien</h1>
                </div>
            </div>

            <div className="switch-menu">
                <div className="menu">
                    <div className={`${selectPartner === "RESPONSABLE" && "active"} switch-menu-btn sans-pro-regular`} onClick={() => setSelectPartner("RESPONSABLE")}>
                        Responsables
                    </div>
                    <div className={`${selectPartner === "AIDE_MENAGERE" && "active"} switch-menu-btn sans-pro-regular`} onClick={() => setSelectPartner("AIDE_MENAGERE")}>
                        Aide-ménagères
                    </div>
                </div>
            </div>

            <div className="header">
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[]}
                                popupClassName="responsable-classe-select"
                            />
                        </div>
                    </div>
                    <div className='blc-right'>
                        <NavLink to={"/inviter-un-tierce"}>
                            <Dropdown.Button
                                style={{ backgroundColor: "red !important", color: 'white !important' }}
                                className="cree-planning"
                                menu={{ items }}>
                            <span style={{ background: "#ff7f0a91 !important", color: 'white !important' }}>
                                <div className="icon">
                                    <img src="./assets/img/equipe-icon-w.png" alt="icone" />
                                </div>
                                Inviter vos équipes sur Kliner
                            </span>
                            </Dropdown.Button>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="stats">
                <Row>
                    <Col lg={12}>
                        <div className="content statistiqueLeft">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/white-stars-icon.png" alt="logo" />
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Performance</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Surveiller l’efficacité de vos équipes de ménage</div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockBlue">
                                            <div className="indication">
                                                <Popover placement="top" content={contentInfoSuccess} overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info" />
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">{stats.prestations_successful_percentage ? parseFloat(stats.prestations_successful_percentage).toFixed(2) : 0}%</div>
                                            <div className="label sans-pro-light">Prestations réussies</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockOrange">
                                            <div className="indication">
                                                <Popover placement="top" content={contentInfoSaturation} overlayClassName="infoContentLogement">
                                                    <div className="info-equipe">
                                                        <img src="/assets/img/info-circled.png" alt="info" />
                                                    </div>
                                                </Popover>
                                            </div>
                                            <div className="number">{stats.saturation_percentage ? parseFloat(stats.saturation_percentage).toFixed(2) : 0}%</div>
                                            <div className="label sans-pro-light">Taux de saturation</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="content statistiqueRight">
                            <div className="head">
                                <div className="image">
                                    <img src="./assets/img/activity.png" alt="logo" />
                                </div>
                                <div className="text">
                                    <div className="title sans-pro-semi-bold">Activité générale</div>
                                    <div className="description color-5B6E8C sans-pro-regular">Garder un œil sur la répartition du travail au sein de vos équipes</div>
                                </div>
                            </div>
                            <div className="statistique">
                                <Row>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyLeft">
                                            <div className="indication">

                                            </div>
                                            <div className="number">{stats.totalite_prestation_percentage ? parseFloat(stats.totalite_prestation_percentage).toFixed(2) : 0}%</div>
                                            <div className="label sans-pro-light">De la totalité de vos prestations</div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="blocStat blockGreyRight">
                                            <div className="indication"></div>
                                            <div className="number">{stats.prestations_total_finished ? parseFloat(stats.prestations_total_finished).toFixed(0) : 0}</div>
                                            <div className="label sans-pro-light">Prestations effectuées</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="content">
                <Table
                    className="equipe-table"
                    pagination={{ pageSize: 10 }}
                    rowSelection={rowSelection}
                    dataSource={responsables}
                    columns={columns}
                    rowKey={(record) => record.key}
                    locale={locale}
                />
            </div>
            <Drawer title="" onClose={onClose} open={open} width={550} className="equipe-custom-drawer-class">
                <EquipeDrawerPage equipe={equipe} />
            </Drawer>

            <AttribuerModalView
                status={showModalAttribution}
                handleCancel={() => setShowModalAttribution(false)}
            />
            <PourboireModalView
                status={showModalPourboire}
                handleCancel={() => {
                    setShowModalPourboire(false);
                    setShowModalPourboireConfirmation(true);
                }}
            />
            <PourboireConfirmationModalView
                status={showModalPourboireConfirmation}
                handleCancel={() => {
                    setShowModalPourboireConfirmation(false);
                    setShowNotif(true);
                }}
            />
            {showNotif && <SuccessNotification
                show={showNotif}
                placement={"bottomRight"}
                timer={5}
                message={"Pourboire versé"}
                resetShow={() => setShowNotif(false)}
            />}

            <BloquerProfilModalView
                status={showModalBloquer}
                handleCancel={() => setShowModalBloquer(false)}
            />
        </div>
    );
}

export default EquipePage;
