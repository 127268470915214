import React, { useState, useEffect } from 'react';
import {Row, Col, Divider, Input, Select, Dropdown, Table, Badge, Button} from "antd";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { IoAdd } from "react-icons/io5";
import ProgrammerManuellementModalView from "../../planning/modal/programmer-manuellement";
import ValidProgrammationModalView from "../../planning/modal/planning-success";
import { usePrestationFetchMutation } from '../../planning/services/planning-api';
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from '../services/logement-slice';
import moment from "moment";
import "moment/locale/fr";
import {
    convertDateStringToTimeString,
    convertDateStringToTimeStringComplete,
    convertDateStringToTimeStringDateOnly
} from '../../../utils/global-var';
import PlanningDetail from "../../planning/planning_detail";

const { TextArea } = Input;


function PlanningPrestationView({ logement }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalProgrammeApercu, setShowModalProgrammeApercu] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(false);
    const [prestationFetch] = usePrestationFetchMutation()
    const [loading, setLoading] = useState(false);
    const [prestations, setPrestations] = useState([]);
    const [status, setStatus] = useState([]);
    const [aidemenageres, setAidemenageres] = useState([]);
    const [services, setServices] = useState([]);
    const [prestationId, setPrestationId] = useState(null);
    const [height, setHeight] = useState(window.innerHeight);
    const dispatch = useDispatch();

    const items = [];

    const [showModal, setShowModal] = useState(false);
    //console.log(logement);
    console.log(height);
    const columns = [
        {
            title: 'Service',
            key: 'service',
            render: (record) => (
                <div style={{cursor:"pointer"}} onClick={() => {setPrestationId(record.id); setShowModalProgrammeApercu(true);}}>
                    {record.service}
                </div>
            ),
        },
        {
            title: "Date de prestation",
            key: "creneau_intervention_debut",
            render: (record) => (
                <span> {convertDateStringToTimeStringDateOnly(record.creneau_intervention_debut)}</span>
            ),
        },

        {
            title: "Créneau d'intervention",
            key: "creneau_intervention_debut",
            render: (record) => (
                <span>Entre {convertDateStringToTimeString(record?.creneau_intervention_debut)} et {convertDateStringToTimeString(record?.creneau_intervention_fin)}</span>
            ),
        },

        {
            title: "Statut",
            key: "status",
            render: (record) => (
                <span> {customStatus(record.status)}</span>
            ),
        },

        {
            title: 'Forfait',
            key: 'cout_total',
            render: (record) => (
                <span> {record.cout_total} €</span>
            ),
        },
        {
            title: 'Créé le',
            key: "createdAt",
            render: (record) => (
                <span> {convertDateStringToTimeStringComplete(record.createdAt)}</span>
            ),
        },
    ];

    const customStatus = (value) => {
        switch (value) {
            case "En cours":
                return (<Badge color="#FDAC3D" count={"En cours"} />);
            case "Programmé":
                return (<Badge color="#333333" count={"À faire"} />);
            case "Annulé":
                return (<Badge color="#DF2E49" count={"Annulé"} />);
            case "Terminé":
                return (<Badge color="#00C875" count={"Terminé"} />);
            default:
                return (<Badge color="#333333" count={"À faire"} />);
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const handleFetchPrestations = async (data) => {
        setLoading(true)
        prestationFetch(data).unwrap()
            .then((res) => {
                setPrestations(res.data)
                setLoading(false)
                setAidemenageres(res.filter.aide_menageres)
                setServices(res.filter.services)
                setStatus(res.filter.status)
                dispatch(setRefresh(false));
            })
            .catch(() => setLoading(false));
    };


    useEffect(() => {
        const data = ({ "logements": [logement.id] })
        handleFetchPrestations(data)
    }, []);



    // FILTERS
    const handleFilterStatus = (value) => {
        handleFetchPrestations({ status: [value] })
    };

    const handleFilterResponsable = (value) => {
        if (value === "Tout") {
            handleFetchPrestations({ "logements": [logement.id] })
        } else {
            handleFetchPrestations({ intervenants: [value] })
        }
    };

    const handleFilterServices = (value) => {
        if (value === "Tout") {
            handleFetchPrestations({ "logements": [logement.id] })
        } else {
            handleFetchPrestations({ services: [value] })
        }
    };

    return (
        <div className="prestation-planning blc-right">
            <div className="title">Planning des prestations</div>
            <Divider />
            <br /> <br />
            <div className='flex justify-content-space-between'>
                <div className=''>
                    <div className='blc-filter'>
                        <span className="sans-pro-light color-5B6E8C">Statut:</span>
                        <Select
                            defaultValue="Tout"
                            onChange={handleFilterStatus}
                            suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                            options={[
                                {
                                    value: 'Programmé',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="icone">
                                            <img src="./assets/img/a-faire-icon.png" alt="icone" />
                                        </div>
                                        <div className="text color-213856">A faire</div>
                                    </div>,
                                },
                                {
                                    value: 'En cours',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="icone">
                                            <img src="./assets/img/Play-Icon-black.png" alt="icone" />
                                        </div>
                                        <div className="text color-213856">
                                            En cours
                                        </div>
                                    </div>,
                                },
                                {
                                    value: 'Terminé',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="icone">
                                            <img src="./assets/img/done-icon-black.png" alt="icone" />
                                        </div>
                                        <div className="text color-213856">
                                            Terminée
                                        </div>
                                    </div>,
                                },
                                {
                                    value: 'Annulé',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="icone">
                                            <img src="./assets/img/Presta-annulee-icone.png" alt="icone" />
                                        </div>
                                        <div className="text color-213856">
                                            Annulée
                                        </div>
                                    </div>,
                                },
                            ]}
                            popupClassName="status-classe-select-planning-login"
                        />
                    </div>

                    <div className='blc-filter'>
                        <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                        <Select
                            defaultValue="Tout"
                            onChange={handleFilterResponsable}
                            suffixIcon={
                                <div className="icon-logement-select">
                                    <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                </div>
                            }
                            options={[
                                {
                                    value: 'Tout', label:
                                        <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">
                                                Tout
                                            </div>
                                        </div>,
                                },
                                ...aidemenageres.map((item) => ({
                                    value: item.id,
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                        <div className="text color-213856">
                                            {item.firstname} {item.lastname}
                                        </div>
                                    </div>,
                                })),
                            ]}
                            popupClassName="status-classe-select-responsable"
                        />
                    </div>

                    <div className='blc-filter'>
                        <span className="sans-pro-light color-5B6E8C">Services:</span>
                        <Select
                            defaultValue="Tout"
                            onChange={handleFilterServices}
                            suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                            options={[
                                {
                                    value: 'Tout',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="text color-213856">Tout</div>
                                    </div>,
                                },
                                {
                                    value: 'Ménage classique',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="text color-213856">Ménage classique</div>
                                    </div>,
                                },
                                {
                                    value: 'Ménage en profondeur',
                                    label: <div className="element-filter-tab">
                                        <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                        <div className="text color-213856">Ménage en profondeur</div>
                                    </div>,
                                },
                            ]}
                            popupClassName="status-classe-select-ville"
                        />
                    </div>
                </div>
                <div className='blc-right'>
                    <Button className={"cree-logement "+(logement?.aide_menagere === null || logement?.responsable === null ? " disable-Button" : "")} type={"primary"}>
                        <div onClick={() => logement?.aide_menagere === null || logement?.responsable === null ? null : setShowModalProgrammerService(true)}>
                            <div className='icon-logement-add'>
                                <PlusOutlined />
                            </div>
                            Programmer un ménage
                        </div>
                    </Button>
                </div>
            </div>
            <Table rowSelection={rowSelection} className="planning-table" pagination={{
                pageSize: height > 900 ? 10 : height > 800 ? 7 : 5
            }}
                columns={columns} dataSource={prestations} locale={{
                    emptyText: (
                        <Row>
                            <Col lg={8}></Col>
                            <Col lg={8}>
                                <div className="empty-Tab-planning">
                                    <img src="/assets/img/prestation.png" alt="airbnb" className="plateforme-img" />
                                    <div className="title">Programmez votre première prestation</div>
                                    <p>
                                        <span>Ajoutez une prestation au planning de votre aide-ménagère <br />et libérez-vous du stress de la gestion du ménage.</span>
                                    </p>
                                    <a href="#">Booster votre taux d’occupation <ArrowRightOutlined className="primary-color" /></a>
                                    <br />
                                    <br />
                                    <button className={"add-prestation sans-pro-regular "+(logement?.aide_menagere === null || logement?.responsable === null ? " disable-Button" : "")} onClick={logement?.aide_menagere === null || logement?.responsable === null ? null : () => setShowModalProgrammerService(true)}>  <IoAdd className='icon' /> Ajouter une prestation </button>
                                </div>
                            </Col>
                            <Col lg={8}></Col>
                        </Row>
                    ),
                }} />

            <ProgrammerManuellementModalView
                logementParams={logement}
                prestationsNb={prestations?.length}
                status={showModalProgrammerService}
                setValidProgram={() => setShowModalProgrammerValid(true)}
                setPrestationId = {setPrestationId}
                handleCancel={() => setShowModalProgrammerService(false)}
            />
            <ValidProgrammationModalView
                status={showModalProgrammerValid}
                onOpenNewPrestation={setShowModalProgrammerService}
                onOpenDetail={setShowModalProgrammeApercu}
                comeFrom={"planning"}
                handleCancel={() => setShowModalProgrammerValid(false)}
            />
            {prestationId !== null && showModalProgrammeApercu && <PlanningDetail
                prestationId={prestationId}
                status={showModalProgrammeApercu}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />}
        </div>
    );
}

export default PlanningPrestationView;