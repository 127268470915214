import React, {useState} from 'react';
import "./styles/style.scss"
import {Col, Divider, Dropdown, Menu, Popover, Row, Select, Table} from "antd";
import {IoAdd, IoEllipsisHorizontal, IoEllipsisVertical, IoStar} from "react-icons/io5";
import {ArrowRightOutlined} from "@ant-design/icons";
import RapportPhoto from "./modal/apercu";
import {useMediaprestationFetchQuery} from "./services/mediaprestation-api";
import moment from "moment";
import {convertDateStringToTimeStringDateOnly} from "../../utils/global-var";
function LogistiquePage() {
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const { data: mediaList, refetch } = useMediaprestationFetchQuery({start:
            moment()
                .subtract(21, 'days').format('YYYY-MM-DD'),
        end: moment()
            .format('YYYY-MM-DD')
    });
    console.log(mediaList);
    return (
        <div className='logistique-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Reportage photos ou vidéos</h1>
                </div>
            </div>

            <div className="photo">
                <Row>
                    <Col lg={24} style={{height: "520px", overflowY:"scroll"}}>
                        {mediaList?.data?.length !== 0 && mediaList?.data?.map((item, index) => (
                            <Row className="rapport_photo" key={"rapport_" + index} onClick={() => {setSelectedDate(item["prestation_date"]);setShowModal(true)}}>
                                <Col lg={3}>
                                    {item["total_videos"] !== "0" && (
                                        (item["total_images"] !== "0" &&
                                            <div className="type-mini-video">
                                                <img src="./assets/img/photo-controle.png" alt="icon"/>
                                            </div>) ||
                                        (item["total_images"] === "0" &&
                                            <div className="type">
                                                <img src="./assets/img/video-prestation.png" alt="icon"/>
                                            </div>)
                                    )}
                                    {item["total_images"] !== "0" && (
                                        (item["total_videos"] !== "0" &&
                                            <div className="type-mini-photo">
                                                <img src="./assets/img/video-prestation.png" alt="icon"/>
                                            </div>) ||
                                        (item["total_videos"] === "0" &&
                                            <div className="type">
                                                <img src="./assets/img/photo-controle.png" alt="icon"/>
                                            </div>)
                                    )}
                                    {item["total_images"] === "0" && item["total_videos"] === "0" && <div className="type">
                                        <img src="./assets/img/no-file-controle.png" alt="icon"/>
                                    </div>}
                                </Col>
                                <Col lg={7}>
                                    <div className="date sans-pro-semi-bold">{convertDateStringToTimeStringDateOnly(item["prestation_date"], true)}</div>
                                </Col>
                                <Col lg={6}>
                                    <div className="prestation">{item["total_finished_prestation"]} prestations</div>
                                </Col>
                                <Col lg={6}>
                                    <div className="prestation">{item["total_images"] !== "0" && item["total_images"]+" photo"+(parseInt(item["total_images"]) > 1 ? "s": "")+(item["total_videos"] !== "0" ? " & " : " ")} {item["total_videos"] !== "0" && item["total_videos"]+" video"+(parseInt(item["total_videos"]) > 1 ? "s" : "")}</div>
                                </Col>
                                <Col lg={2}>
                                    <div className="menu">
                                        <Dropdown
                                            overlay={<Menu items={null} />}
                                            placement="bottomLeft" trigger={['click']}>
                                            <IoEllipsisHorizontal />
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                        {mediaList?.data?.length === 0 && <div>Aucune prestation en étude pour le moment.</div>}
                    </Col>
                </Row>
            </div>
            <br/>
            <br/>
            <br/>
            {selectedDate !== null && <RapportPhoto
                status={showModal}
                date={selectedDate}
                resetSelectDate={setSelectedDate}
                prestations={mediaList?.data}
                handleCancel={() => setShowModal(false)}
            />}
        </div>
    );
}

export default LogistiquePage;
