import React, { useEffect, useState } from "react";
//import SurfaceView from "./surface-v2-view";
import SurfaceView from "./surface-view";
import { useSelector, useDispatch } from "react-redux";
import AdresseView from "./adresse/adresse-view";
import { Modal, Row, Col, Popover, Input, message, Spin } from "antd";
import GestionLingeView from "./gestion-linge-view";
import GestionCleView from "./gestion-cle/gestion-cle-view";
import KitAccueilView from "./kit-accueil/kit-accueil-view";
import MaterielProduitMenagerView from "./materiel-produit-menager/materiel-produit-menager-view";
import AutomatisationView from "./automatisation/automatisation-view";
import PlanningPrestationView from "./prestations-view";
import EquipeDeMenageView from "./equipe-de-menage-view";
import { useCartFetchAmenitiesQuery, useLogementFetchOne2Mutation, useLogementUpdateMutation } from "../services/logement-api";
import { convertMinutes, coutMenageClassique } from "../../../utils/global-var";
import {
    addressChecked,
    EquipeMenage,
    gestionCleChecked,
    gestionLingeChecked,
    kitAccueilChecked,
    materielProduitChecked,
    surfaceChecked
} from "../utils/logement-utils";
import CalendarEmptyModal from "./automatisation/view-modal/calendar-empty-modal";
import CalendarAlreadyUsedModal from "./automatisation/view-modal/calendar-already-used-modal";
import { setRefresh } from "../services/logement-slice";
import PaiementParTiersView from "./paiement-par-tiers-view";

function LogementCreate({ status, logementId, handleCancel, setTab }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [logementFetchOne2] = useLogementFetchOne2Mutation()
    const [logement, setLogement] = useState();
    const [nameEditable, setNameEditable] = useState(false);
    const [newName, setNewName] = useState("");
    const [itemSelected, setItemSelected] = useState(setTab);
    const [logementUpdate] = useLogementUpdateMutation();
    const { user } = useSelector((state) => state.auth);
    const { isRefresh } = useSelector((state) => state.logement);
    const cart = useCartFetchAmenitiesQuery(logementId)
    const icalDialog = useSelector((state) => state.logement);
    const dispatch = useDispatch();
    const [libelle, setLibelle] = useState();
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);

    //console.log(logement);
    const handleFetchLogement = async () => {
        logementFetchOne2({ id: logementId }).unwrap()
            .then((res) => { setLogement(res); setLibelle(res.libelle) })
            .catch(() => { });
    };
    const handleChangeName = async () => {
        logementUpdate({ id: logement.id, data: ({ libelle: newName }) }).unwrap()
            .then(() => {
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
                handleFetchLogement();
            } )
            .catch(() => { });
    };

    useEffect(() => {
        if(logementId){
            handleFetchLogement()
        }
    }, [logementId,status]);


    useEffect(() => {
        isRefresh &&
            handleFetchLogement()
    }, [isRefresh]);

    useEffect(() => {
        setItemSelected(setTab);
    }, [logementId, status]);

    const onCancel = () => {
        setItemSelected("Adresse");
        handleCancel();
    };

    const handleSelect = async (value) => setItemSelected(value);

    const contentInfo = (
        <div className="infoLogement">
            <p>
                Forfait fixe qui sera facturé à chaque prestation de <br />
                ménage classique sur ce logement, quelle que soit <br />
                la durée réelle de la prestation. Tarif TTC pour les <br />
                particuliers (50% de crédit d’impôt à déduire) et HT <br />
                pour les entreprises).
            </p>
        </div>
    );

    const handleInput = (event) => {
        const query = event.target.value;
        const newValue = query.replace("🎉", '').trim();
        setLibelle(newValue)
    };


    const handleEdit = () => {
        if ((libelle?.length > 0) && (libelle !== logement.libelle)) {
            setLoading(true)
            logementUpdate({ id: logementId, data: ({ libelle: libelle }) }).unwrap()
                .then(() => {
                    setLoading(false)
                    dispatch(setRefresh(true));
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                })
                .catch(() => { });
        }
    };

    return (
        <Modal
            destroyOnClose
            open={status} footer={null} closable={true} width={2000} onCancel={onCancel} className="logement-create">
            {contextHolder}
            <Spin spinning={loading}>
                <Row>
                    <Col lg={4}>
                        <div className="blc-left">
                            <Row>
                                <Col lg={24} className="logement-input">
                                    <Input value={`🎉 ${libelle}`} onChange={handleInput} onBlur={handleEdit} />
                                </Col>
                            </Row>
                            <div className="time"><span>Durée :</span>
                                <strong> {convertMinutes(logement?.cout_duree.duree_menage_classique)} </strong>| <span>Forfait</span> : <strong>{coutMenageClassique(logement?.cout_duree, user?.profil)} €</strong>
                                <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                                    <div className="info-logement">
                                        <img src="/assets/img/info-circled.png" alt="info" />
                                    </div>
                                </Popover>
                            </div>
                            <div className="menu scroll-none" style={{height: height < 800 ? height / 1.6 : "auto", overflowY: height < 1100 ? "scroll" : "hidden"}}>
                                <h4>ACCÈS </h4>
                                <ul>
                                    <li className={itemSelected === "Adresse" && "active"}
                                        onClick={() => handleSelect("Adresse")}><img src="./assets/img/icon-adresse-colored.png" className="icon-sidebar" />  Adresse
                                        {addressChecked(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                    <li className={itemSelected === "Gestion des clés" && "active"}
                                        onClick={() => handleSelect("Gestion des clés")}><img src="./assets/img/icon-keys-colored.png" className="icon-sidebar" style={{ width: "14px", marginRight: "7.5px" }} /> Gestion des clés
                                        {gestionCleChecked(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                </ul>

                                <h4>CAHIER DES CHARGES</h4>
                                <ul>
                                    <li className={itemSelected === "Surfaces à nettoyer" && "active"}
                                        onClick={() => handleSelect("Surfaces à nettoyer")}><img src="./assets/img/icon-surface-colored.png" className="icon-sidebar" /> Surfaces à nettoyer
                                        {surfaceChecked(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                    <li className={itemSelected === "Gestion du linge" && "active"}
                                        onClick={() => handleSelect("Gestion du linge")}><img src="./assets/img/icon-linge-colored.png" className="icon-sidebar" /> Gestion du linge
                                        {gestionLingeChecked(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                    <li className={itemSelected === "Kit d’accueil" && "active"}
                                        onClick={() => handleSelect("Kit d’accueil")}><img src="./assets/img/icon-kit-accueil-colored.png" className="icon-sidebar" style={{ width: "16px" }} /> Kit d’accueil
                                        {kitAccueilChecked(cart?.data) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                    <li className={itemSelected === "Matériel & Produits ménagers" && "active"}
                                        onClick={() => handleSelect("Matériel & Produits ménagers")}><img src="./assets/img/icon-materiel-colored.png" className="icon-sidebar" style={{ width: "17px", marginRight: "7.5px", marginLeft: "-1.5px" }} /> Matériel & Produits
                                        ménagers
                                        {materielProduitChecked(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                </ul>

                                <h4>MISE EN PLACE</h4>
                                <ul>
                                    <li className={itemSelected === "Équipe de ménage" && "active"}
                                        onClick={() => handleSelect("Équipe de ménage")}><img src="./assets/img/icon-equipe-colored.png" className="icon-sidebar" style={{ width: "17px" }} /> Équipe de ménage
                                        {EquipeMenage(logement) && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                    <li className={itemSelected === "Automatisation" && "active"}
                                        onClick={() => handleSelect("Automatisation")}><img src="./assets/img/icon-automatisation-colored.png" className="icon-sidebar" style={{ width: "16px", marginRight: "7px" }} /> Automatisation
                                        {logement?.icalendarUrl.length !== 0 && <div className="check-image"><img src="./assets/img/check-green.png" alt="check-grey" /></div>}
                                    </li>
                                </ul>

                                <h4>PLANNING</h4>
                                <ul>
                                    <li className={itemSelected === "Prestations" && "active"}
                                        onClick={() => handleSelect("Prestations")}><img src="./assets/img/icon-prestation-colored.png" className="icon-sidebar" /> Prestations</li>
                                </ul>
                                <h4>FACTURATION</h4>
                                <ul>
                                    <li className={itemSelected === "Paiement par tiers" && "active"}
                                        onClick={() => handleSelect("Paiement par tiers")}><img src="./assets/img/empty payment icon.png" className="icon-sidebar" /> Paiement par tiers</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={20}>
                        {
                            itemSelected === "Adresse" ?
                                <AdresseView logement={logement} /> :
                                itemSelected === "Gestion des clés" ?
                                    <GestionCleView logement={logement} /> :
                                    itemSelected === "Surfaces à nettoyer" ?
                                        <SurfaceView logement={logement} /> :
                                        itemSelected === "Gestion du linge" ?
                                            <GestionLingeView logement={logement} /> :
                                            itemSelected === "Kit d’accueil" ?
                                                <KitAccueilView logement={logement} /> :
                                                itemSelected === "Matériel & Produits ménagers" ?
                                                    <MaterielProduitMenagerView logement={logement} /> :
                                                    itemSelected === "Automatisation" ?
                                                        <AutomatisationView logement={logement} /> :
                                                        itemSelected === "Équipe de ménage" ?
                                                            <EquipeDeMenageView logement={logement} readyToAskTeam={addressChecked(logement) && gestionCleChecked(logement) && surfaceChecked(logement) && gestionLingeChecked(logement)} /> :
                                                            itemSelected === "Prestations" ?
                                                                <PlanningPrestationView logement={logement} /> :
                                                                itemSelected === "Paiement par tiers" ?
                                                                    <PaiementParTiersView logement={logement} /> :
                                                                <AdresseView />
                        }
                    </Col>
                </Row>
            </Spin>
            {icalDialog.isEmpty && <CalendarEmptyModal />}
            {icalDialog.isUsed && <CalendarAlreadyUsedModal />}

        </Modal>
    );
}

export default LogementCreate;