import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message} from "antd";
import { useDispatch } from "react-redux";
import { setRefresh } from "../services/logement-slice";
import {useLogementArchiveMutation, useLogementUpdateMutation} from "../services/logement-api";

const ArchiverLogementModalView = ({ logementId, logementIds, status, nbLogement, handleCancel }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [updateLogement] = useLogementUpdateMutation();
    const [updateLogementMultiple] = useLogementArchiveMutation();
    console.log(logementId);
    console.log(logementIds);
    const handleUpdate = () => {
        setIsLoading(true)
        const data = ({ id: logementId, data: {is_archived: true} })
        updateLogement(data).unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                message.success("Logement archivé");
                handleCancel();
                setIsLoading(false)
            })
            .catch((error) => {
                message.error(error);
                handleCancel();
                setIsLoading(false)
            });
    };
    const handleUpdateMultiple = () => {
        setIsLoading(true)
        const data = ({is_archived: true, logementIds: logementIds})
        updateLogementMultiple(data).unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                message.success("Logements archivés");
                handleCancel();
                setIsLoading(false)
            })
            .catch((error) => {
                message.error(error);
                handleCancel();
                setIsLoading(false)
            });
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="archiverLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Souhaitez-vous archiver ce(s) logement(s)</div>
                    </div>
                    <Row>
                        <Col>
                            <span>
                                En poursuivant, les logements sélectionnés seront retirés de la liste de vos logements et vous ne pourrez plus les utiliser pour programmer de nouvelles prestations. Cette opération est (pour le moment) irréversible.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => handleCancel()} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => (logementIds.length > 0 ? handleUpdateMultiple() : handleUpdate())}
                                   className="suivant sans-pro-regular">Confirmer</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default ArchiverLogementModalView;
